import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { FaCheckCircle } from 'react-icons/fa'

import { Center, Flex, Text, Icon } from '@chakra-ui/react'
import PrimaryButton from 'components/UIComponents/PrimaryButton'
import { useQueryParams } from 'hooks'
import api from 'api'

const SignupConfirmation = () => {
	const query = useQueryParams()
	const navigate = useNavigate()
	const [timer, setTimer] = useState(5)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const interval = setInterval(() => {
			if (timer > 0) setTimer(timer - 1)
		}, 1000)

		if (timer === 0 && !loading) {
			navigate('/login')
		}

		return () => clearInterval(interval)
	}, [loading, navigate, timer])

	useEffect(() => {
		const sendData = async () => {
			try {
				setLoading(true)
				await api.post('/signup/email_confirmation', {
					email: query.get('email'),
				})
				ReactGA.event({
					category: 'Email Confirmation',
					action: 'EmailConfirmed',
					label: 'Button',
				})
				setLoading(false)
			} catch (e: any) {
				console.log(e)
				toast.error('Could not create user. Please contact client_support@valenceintel.com')
				setLoading(false)
			}
		}

		if (query.get('email')) {
			void sendData()
		} else {
			toast.error('No email found')
		}
	}, [query])

	return (
		<Flex width='100%' height='100vh' justify='center' align='center'>
			<Flex
				maxW='md'
				minW='sm'
				minH='md'
				borderWidth='1px'
				borderRadius='lg'
				bg='white'
				direction='column'
				padding='40px'
				justify='space-between'
			>
				<Center>
					<Icon as={FaCheckCircle} color='vBlue.600' fontSize='7xl' />
				</Center>
				<Text fontWeight='bold' align='center' fontSize='3xl'>
					Email Verified
				</Text>

				<Text align='center'>Congratulations, you have successfully verified your email address.</Text>

				<Text align='center'>
					You will be redirected to the login page in... <b>{timer}</b>
				</Text>

				<Center>
					<PrimaryButton onClick={() => navigate('/login')} isDisabled={loading}>
						Go to login
					</PrimaryButton>
				</Center>
			</Flex>
		</Flex>
	)
}

export default SignupConfirmation
