import { Center } from '@chakra-ui/react'
import Loader from 'components/Loader'
import { CurrentMarketplaceContext } from 'context/CurrentMarketplace'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function LandingPage() {
	const { currentMarketplace } = useContext(CurrentMarketplaceContext)
	const navigation = useNavigate()

	useEffect(() => {
		if (currentMarketplace) {
			navigation(currentMarketplace.url)
		}
	}, [currentMarketplace, navigation])

	return (
		<Center width='100%'>
			<Loader />
		</Center>
	)
}

export default LandingPage
