import axios from 'axios'
import axiosRetry from 'axios-retry'
import { fetchAuthSession } from 'aws-amplify/auth'

const walmartApi = axios.create({
	baseURL: import.meta.env.VITE_WALMART_URL,
})

//if api returns a 401, refetch a valid refresh token and try again
walmartApi.interceptors.response.use(
	(response) => response,
	async function (error) {
		const originalRequest = error.config
		if (error?.response?.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true
			const { accessToken } = (await fetchAuthSession()).tokens ?? {}
			walmartApi.defaults.headers.common['accesstoken'] = accessToken?.toString()
			originalRequest.headers.accesstoken = accessToken?.toString()
			return walmartApi(originalRequest)
		}
		return Promise.reject(error)
	}
)
axiosRetry(walmartApi, { retries: 3 })
export default walmartApi
