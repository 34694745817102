import { createRoot } from 'react-dom/client'
import { Route, HashRouter as Router, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { Amplify } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import ReactGA from 'react-ga4'
import { ChakraProvider, Box } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import Signup from 'views/Signup'
import SignupInvite from 'views/SignupInvite'
import SignupConfirmation from 'views/SignupConfirmation'
import Login from 'views/Login'
import ForgotPassword from 'views/ForgotPassword'

import ErrorFallback from 'components/ErrorFallback'
import TermsAndConditions from 'views/TermsAndConditions'
import PrivacyPolicy from 'views/PrivacyPolicy'

import { theme } from './styles/theme'
import Questionnaire from 'views/Questionnaire'
import CheckEmail from 'views/CheckEmail'
import PasswordReset from 'views/PasswordReset/PasswordReset'
import InvalidConfirmationCode from 'views/InvalidConfirmationCode/InvalidConfirmationCode'
import EmbeddableSignUp from 'views/EmbeddableSignUp'
import Error from 'views/Error'
import UnprotectedRoute from 'components/UnprotectedRoute'
import ProtectedRoutes from './ProtectedRoutes'
import 'mapbox-gl/dist/mapbox-gl.css'

import { TourProvider } from '@reactour/tour'

import tourSteps from './tourSteps'
import CarrierCredentials from 'views/CarrierCredentials/CarrierCredentials'
import Maintenance from 'views/Maintenance'

ReactGA.initialize('G-JVF3CW1R15')

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolId: 'us-east-1_qaMOnpDii',
			userPoolClientId: '5uvgrgostasfhmdhsc6146p6ai',
		},
	},
})

Sentry.init({
	dsn: 'https://faee009a1c3d955a80dc81983e916f01@o4507052453593088.ingest.us.sentry.io/4507052499009536',
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	environment: import.meta.env.PROD ? 'production' : 'development',
	// Performance Monitoring
	tracesSampleRate: 0.1, //  Capture 10% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: [/^https:\/\/valenceintel\.com\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 1000 * 60 * 60 /* 1 hour */,
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			retry: 1,
		},
	},
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

if (import.meta.env.VITE_MAINTENANCE_MODE === 'true') {
	root.render(
		<Sentry.ErrorBoundary
			fallback={({ error }) => <ErrorFallback error={error} />}
			beforeCapture={(scope) => scope.setTag('location', 'Top Level')}
		>
			<ChakraProvider theme={theme}>
				<Maintenance />
			</ChakraProvider>{' '}
		</Sentry.ErrorBoundary>
	)
} else {
	root.render(
		<Sentry.ErrorBoundary
			fallback={({ error }) => <ErrorFallback error={error} />}
			beforeCapture={(scope) => scope.setTag('location', 'Top Level')}
		>
			<ChakraProvider theme={theme}>
				<QueryClientProvider client={queryClient}>
					<Box id='rootBox' width='100%' height='100vh' overflowX='hidden' bgColor='gray.200'>
						<Toaster
							position='bottom-right'
							toastOptions={{
								success: {
									style: {
										background: '#4CAF50',
										color: 'white',
									},
								},
								error: {
									style: {
										background: '#C62828',
										color: 'white',
									},
								},
							}}
						/>
						<Router>
							<TourProvider steps={tourSteps}>
								<Routes>
									<Route path={'login'} element={<UnprotectedRoute component={Login} tag={'login'} />} />
									<Route
										path='/questionnaire/:identifier'
										element={<UnprotectedRoute component={Questionnaire} tag='Questionnaire' />}
									/>
									<Route path='/signup/:referrer?' element={<UnprotectedRoute component={Signup} tag='Sign Up' />} />
									<Route
										path='/embedded_signup/:referrer?'
										element={<UnprotectedRoute component={EmbeddableSignUp} tag='Embedded Sign Up' />}
									/>
									<Route
										path='/signupInvite/:invite?'
										element={<UnprotectedRoute component={SignupInvite} tag='Signup Invite' />}
									/>
									<Route
										path='/signup_confirmation'
										element={<UnprotectedRoute component={SignupConfirmation} tag='Signup Confirmation' />}
									/>
									<Route path='/check_email' element={<UnprotectedRoute component={CheckEmail} tag='Check Email' />} />
									<Route
										path='/invalid_code'
										element={<UnprotectedRoute component={InvalidConfirmationCode} tag='Invalid Code' />}
									/>
									<Route
										path='/forgot_password'
										element={<UnprotectedRoute component={ForgotPassword} tag='Forgot Password' />}
									/>
									<Route
										path='/reset_password'
										element={<UnprotectedRoute component={PasswordReset} tag='Password Reset' />}
									/>
									<Route
										path='/terms_and_conditions'
										element={<UnprotectedRoute component={TermsAndConditions} tag='Terms and Conditions' />}
									/>
									<Route
										path='/privacy_policy'
										element={<UnprotectedRoute component={PrivacyPolicy} tag='Privacy Policy' />}
									/>
									<Route path='/error/:id?' element={<UnprotectedRoute component={Error} tag='Error' />} />
									<Route
										path='/carrier_credentials'
										element={<UnprotectedRoute component={CarrierCredentials} tag='Carrier Credentials' />}
									/>
									<Route path='/*' element={<ProtectedRoutes />} />
								</Routes>
							</TourProvider>
						</Router>
					</Box>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</ChakraProvider>
		</Sentry.ErrorBoundary>
	)
}
