import { useParams, useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet'
import { Box, Flex, Link } from '@chakra-ui/react'
import PrimaryButton from 'components/UIComponents/PrimaryButton'
import SecondaryButton from 'components/UIComponents/SecondaryButton'
import Card from 'components/UIComponents/Card'
const Error = () => {
	const { id } = useParams<any>()
	const navigate = useNavigate()
	return (
		<div>
			<Helmet>
				<title>Valence Intelligence | Error</title>
			</Helmet>

			<Flex width='100%' height='100vh' justify='center' align='center'>
				<Card
					display='flex'
					flexDirection='column'
					justifyContent='center'
					alignContent='center'
					width='70%'
					maxWidth='600px'
					padding={5}
				>
					<Box as='span' fontSize={60} color='vOrange.500' textAlign='center'>
						Uh Oh
					</Box>
					<Box as='span' fontSize={20} color='vBlue.700' mt={3} mb={3} textAlign='center'>
						Looks like something went wrong
					</Box>
					<Box as='span' mt={3} mb={3} textAlign='center'>
						{id && (
							<Box as='span' textAlign='center'>
								Error id: {id}{' '}
							</Box>
						)}
					</Box>
					<Box as='span' mt={3} mb={3} textAlign='center'>
						If you would like help please email{' '}
						<Link
							href='mailto:client_support@valenceintel.com'
							color='black'
							_visited={{ color: 'black' }}
							_hover={{ color: 'black' }}
							isExternal
							textDecoration='underline'
						>
							client_support@valenceintel.com
						</Link>{' '}
						with the error ID
					</Box>
					<Box as='span' mt={3} mb={3} textAlign='center'>
						You can also try going back, or to the home page
					</Box>
					<Flex justifyContent='space-evenly' mt={3} mb={3}>
						<SecondaryButton onClick={() => navigate(-1)}>Back</SecondaryButton>
						<PrimaryButton
							onClick={() => {
								navigate('/')
							}}
						>
							Home
						</PrimaryButton>
					</Flex>
				</Card>
			</Flex>
		</div>
	)
}
export default Error
