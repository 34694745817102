import { useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import Helmet from 'react-helmet'
import Link from 'components/UIComponents/Link'

import {
	Box,
	Flex,
	Image,
	BoxProps,
	Button,
	Switch,
	FormLabel,
	useBreakpointValue,
	Show,
	Stack,
} from '@chakra-ui/react'
import { EmailIcon } from '@chakra-ui/icons'

import { signIn } from 'aws-amplify/auth'
import { toast } from 'react-hot-toast'
import { Link as RRLink, useNavigate } from 'react-router-dom'

import { useUser } from 'hooks/user'
import { useRefreshData } from 'hooks/refreshData'
import PasswordInput from 'components/PasswordInput'
import FormInput from 'components/FormInput'

import { validateEmail } from 'utils/formValidation'
import Logo from 'images/ValenceLogoLight.png'

type Submission = {
	email: string
	password: string
}

const Signup = () => {
	const [loading, setLoading] = useState(false)
	const { user: currentUser } = useUser()
	const { refreshAllData } = useRefreshData()
	const navigate = useNavigate()
	const [showPassword, setShowPassword] = useState(false)
	const toggleShowPassword = () => setShowPassword(!showPassword)

	const {
		handleSubmit,
		control,
		formState: { errors },
		watch,
	} = useForm<Submission>()

	const email = watch('email', '')

	const onSubmit = async (credentials: Submission) => {
		try {
			setLoading(true)
			ReactGA.event({
				category: 'login',
				action: 'login',
				label: 'Button',
			})
			try {
				await signIn({ username: credentials.email, password: credentials.password })
			} catch (e: any) {
				//the user has yet to confirm their email so redirect them to the confirmation page
				if (e?.code === 'UserNotConfirmedException') {
					navigate(`/check_email?email=${credentials.email}`)
				} else {
					toast.error(e.message)
					setLoading(false)
				}
				return
			}

			await refreshAllData()
			navigate('/')
		} catch (e: any) {
			toast.error(e.message)
			setLoading(false)
		}
	}

	if (currentUser?.username) navigate('/')

	const formWidth = useBreakpointValue({ base: '90%', md: '400px' })
	const logoHeight = useBreakpointValue({ base: '70px', md: '73px' })
	const buttonWidth = useBreakpointValue({ base: '100%', md: '400px' })
	const boxPadding = useBreakpointValue({ base: 5, md: 10 })

	return (
		<Flex
			w='100%'
			h='100vh'
			align='center'
			justify='center'
			bgImage={`url(https://mihostedimages.s3.amazonaws.com/Login_bg.png)`}
			bgSize='cover'
			bgPosition='center'
			p={{ base: 4, md: 0 }}
			minW='300px'
		>
			<Stack w='100%' h='100%' align='center' justify='center' direction='column' gap={5}>
				<Helmet>
					<title>Valence Intelligence | Login</title>
				</Helmet>
				<Flex
					p={boxPadding}
					bgColor='white'
					borderRadius='10px'
					boxShadow='md'
					flexDirection='column'
					alignItems='center'
					w={formWidth}
					maxW='400px'
					minW='300px'
				>
					<Image src={Logo} alt='logo' mb='23px' height={logoHeight} />

					<Box
						as='form'
						onSubmit={handleSubmit(onSubmit)}
						display='flex'
						flexDirection='column'
						justifyContent='space-between'
						alignItems='center'
						w='100%'
					>
						<Box {...inputBoxStyles} mt='30px'>
							<FormInput
								name='email'
								control={control}
								rules={{
									required: 'Email is required',
									validate: {
										checkEmail: (v: string) => validateEmail(v) || 'Invalid email address',
									},
								}}
								fontSize='14px'
								label='Email'
								helperText={errors?.email?.message}
								error={errors?.email ? true : false}
								Icon={<EmailIcon color='gray.400' fontSize='22px' />}
								placeholder='Enter Email Address'
							/>
						</Box>
						<Box {...inputBoxStyles}>
							<PasswordInput
								name='password'
								control={control}
								rules={{ required: 'Password is required' }}
								helperText={errors?.password?.message}
								error={errors?.password ? true : false}
								label='Password'
								showPassword={showPassword}
							/>
						</Box>

						<Stack
							m='15px'
							fontSize='xs'
							alignItems='center'
							justifyContent='space-between'
							w='100%'
							direction={{ base: 'column', sm: 'row' }}
						>
							<Flex alignItems='center' color='gray'>
								<Switch
									colorScheme='blue'
									id='show-password'
									mr='2'
									onChange={toggleShowPassword}
									isChecked={showPassword}
								/>
								<FormLabel htmlFor='show-password' fontSize='xs' whiteSpace='nowrap' mb='0'>
									Show Password
								</FormLabel>
							</Flex>

							<Show above='sm'>
								<Flex>
									<Box height='20px' width='1px' bgColor='gray' mx='12px' alignSelf='center' />
								</Flex>
							</Show>

							<Flex alignItems='center'>
								<Button as={RRLink} to='/forgot_password' variant='link' fontSize='xs' color='gray'>
									Forgot your Password
								</Button>
							</Flex>
						</Stack>
						<Flex align='center' justify='center' w='100%' marginTop='10px'>
							<Button
								size='md'
								height='40px'
								width={buttonWidth}
								type='submit'
								bgColor={email ? 'gray.400' : '#149BD7'}
								_hover={email ? {} : { bg: '#149BD7' }}
								cursor={email ? 'pointer' : 'not-allowed'}
								color={'white'}
								sx={{
									backgroundColor: loading || !email ? 'gray.300' : '#149BD7',
								}}
							>
								Login
							</Button>
						</Flex>
						<Box m='15px' fontSize='xs' textAlign='center'>
							Not registered yet?
							<Link as={RRLink} to='/signup' color='#149BD7' fontWeight='bold' ml='5px'>
								Create an Account
							</Link>
						</Box>
					</Box>
				</Flex>
				<Box width='100%' textAlign='center' color='white' fontSize='xs' fontWeight='bold'>
					© 2024, Valence Intelligence
				</Box>
			</Stack>
		</Flex>
	)
}

export default Signup

const inputBoxStyles: BoxProps = {
	w: '100%',
	m: '20px 0px',
}
