import PrimaryButton from 'components/UIComponents/PrimaryButton'
import SecondaryButton from 'components/UIComponents/SecondaryButton'
import { ButtonGroup } from '@chakra-ui/react'
import Helmet from 'react-helmet'

import styles from './ErrorFallback.module.scss'

const ErrorFallback = ({ error }: any) => {
	if (error.message.includes('Failed to fetch dynamically imported module')) {
		window.location.reload()
		return <div />
	}

	return (
		<div>
			<Helmet>
				<title>Valence Intelligence | Error</title>
			</Helmet>
			<div className={styles.errorContainer}>
				<h1 className={styles.faceWrapper}>{':('}</h1>
				<h1 className={styles.errorHeader}>Looks like an error occurred</h1>
				<h3 className={styles.errorSubHeader}>Sorry about that </h3>
				<div className={styles.escapeButtonContainer}>
					<ButtonGroup spacing={4}>
						<PrimaryButton
							onClick={() => {
								window.location.href = 'https://app.valenceintel.com/#/'
								setTimeout(() => {
									// This was refreshing the window before the redirect could happen
									window.location.reload()
								}, 500)
							}}
						>
							Home
						</PrimaryButton>
						<SecondaryButton
							onClick={() => {
								window.location.href = 'https://misupport.zendesk.com/hc/en-us'
							}}
						>
							Help
						</SecondaryButton>
					</ButtonGroup>
				</div>
			</div>
		</div>
	)
}

export default ErrorFallback
