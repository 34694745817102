import { createElement } from 'react'

import * as Sentry from '@sentry/react'
import ErrorFallback from 'components/ErrorFallback'

type UnprotectedRouteProps = {
	component: any
	tag: string
}
const UnprotectedRoute = ({ component, tag }: UnprotectedRouteProps) => {
	return (
		<Sentry.ErrorBoundary
			fallback={({ error }) => <ErrorFallback error={error} />}
			beforeCapture={(scope) => scope.setTag('location', tag)}
		>
			{createElement(component)}
		</Sentry.ErrorBoundary>
	)
}

export default UnprotectedRoute
