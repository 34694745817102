import { createElement } from 'react'
import { Navigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { useUser } from 'hooks/user/user'
import ErrorFallback from 'components/ErrorFallback'

type ProtectedRouteProps = {
	component: any
	tag: string
}

const ProtectedRoute = ({ component, tag }: ProtectedRouteProps) => {
	const { user, isLoading } = useUser()

	if (isLoading) return <div />

	return user ? (
		<Sentry.ErrorBoundary
			fallback={({ error }) => <ErrorFallback error={error} />}
			beforeCapture={(scope) => scope.setTag('location', tag)}
		>
			{createElement(component)}
		</Sentry.ErrorBoundary>
	) : (
		<Navigate to='/login' />
	)
}

export default ProtectedRoute
