import { ChangeEvent, useRef, useState } from 'react'
import Helmet from 'react-helmet'
import { useForm, Controller } from 'react-hook-form'
import { MdOutlineSmartphone } from 'react-icons/md'
import {
	Box,
	Checkbox,
	Flex,
	Image,
	Text,
	useBreakpointValue,
	Select,
	FormLabel,
	Tooltip,
	VStack,
} from '@chakra-ui/react'
import { Link as RRLink, useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import ReCaptcha from 'react-google-recaptcha'
import ReactGA from 'react-ga4'

import PrimaryButton from 'components/UIComponents/PrimaryButton'
import Link from 'components/UIComponents/Link'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/react'
import { validateEmail, validatePassword, validateRequired, checkPasswordMatch } from 'utils/formValidation'
import PasswordInput from 'components/PasswordInputDarkMode'
import FormInput from 'components/FormInputDarkMode'

import Logo from 'images/ValenceLogoLight.png'
import api from 'api'
import { EmailIcon, InfoIcon, LockIcon } from '@chakra-ui/icons'
import { FaUser } from 'react-icons/fa'
import { BsBuilding } from 'react-icons/bs'

type Submission = {
	name: string
	phoneNumber: string
	email: string
	company: string
	password: string
	confirmPassword: string
	estimatedAnnualRevenue?: string
	estimatedYearlyRevenueLow: number
	estimatedYearlyRevenueHigh: number
	referrer: string
	terms: boolean
}

const yearlyRevenueOptions = [
	{ key: 1, label: '$0 - $1M', highValue: 1000000, lowValue: 0 },
	{ key: 2, label: '$1M - $5M', highValue: 5000000, lowValue: 1000000 },
	{ key: 3, label: '$5M - $10M', highValue: 10000000, lowValue: 5000000 },
	{ key: 4, label: '$10M - $50M', highValue: 50000000, lowValue: 10000000 },
	// High value is included here to be explicit about the range
	{ key: 5, label: '$50M +', highValue: null, lowValue: 50000000 },
]

const Signup = () => {
	const [loading, setLoading] = useState(false)
	const { referrer } = useParams<any>()
	const navigate = useNavigate()

	const recaptchaRef = useRef<any>()

	const {
		handleSubmit,
		setValue,
		control,
		watch,
		formState: { errors },
		reset,
	} = useForm<Submission>({})

	const onSubmit = async (formData: Submission) => {
		try {
			if (await isBot()) {
				toast.error('Could not verify CAPTCHA. Please contact us at client_support@valenceintel.com.')
				// reset the state but keep the data
				reset(formData)
				return
			}
			setLoading(true)
			try {
				ReactGA.event({
					category: 'Signup Screen',
					action: 'Signup Button',
					label: 'Button',
				})
			} catch (e) {
				console.log(e)
			}
			delete formData.estimatedAnnualRevenue
			await api.post('/signup', {
				...formData,
				referrerUrl: document.referrer,
			})
			navigate(`/check_email?email=${formData.email}`)
		} catch (e: any) {
			if (e?.response?.data?.message) toast.error(e?.response?.data?.message)
			if (e?.response?.data?.name) toast.error(e?.response?.data?.name)
			setLoading(false)
		}
	}

	const validateCaptcha = async () => {
		const token = await recaptchaRef.current.executeAsync()
		await api.post('/signup/captcha', { captcha: token })
	}

	async function isBot() {
		try {
			await validateCaptcha()
		} catch (e: any) {
			toast.error(e.response.data.error.message)
			return true
		}

		return false
	}

	const formWidth = useBreakpointValue({ base: '90%', md: '400px' })
	const logoHeight = useBreakpointValue({ base: '70px', md: '73px' })
	const buttonWidth = useBreakpointValue({ base: '100%', md: '400px' })
	const boxPadding = useBreakpointValue({ base: 5, md: 10 })

	const handleRevenueChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const optionKey = event.target.value
		const entry = yearlyRevenueOptions.find((option) => option.key === parseInt(optionKey))
		if (!entry) return

		setValue('estimatedYearlyRevenueLow', entry.lowValue)
		if (entry.highValue) setValue('estimatedYearlyRevenueHigh', entry.highValue)
	}

	return (
		<Flex
			align='center'
			bgImage={`url(https://mihostedimages.s3.amazonaws.com/Login_bg.png)`}
			bgPosition='center'
			flexDirection='column'
			height='100vh'
			overflow='auto'
		>
			<Helmet>
				<title>Valence Intelligence | Signup{referrer ? ` ${referrer}` : ''}</title>
			</Helmet>
			<VStack w='100%' gap={5}>
				<Flex
					mt='30px'
					p={boxPadding}
					bgColor='white'
					borderRadius='10px'
					boxShadow='md'
					flexDirection='column'
					alignItems='center'
					w={formWidth}
					maxW='400px'
					minW='300px'
				>
					<Image src={Logo} alt='logo' mb='23px' height={logoHeight} />
					<Box
						as='form'
						onSubmit={handleSubmit(onSubmit)}
						display='flex'
						flexDirection='column'
						justifyContent='space-between'
						alignItems='center'
						w='100%'
					>
						<Box {...inputBoxStyles} mt='30px'>
							<FormLabel htmlFor='name'>Full Name</FormLabel>
							<FormInput
								name='name'
								control={control}
								rules={{
									validate: {
										checkRequired: (v: string) => validateRequired(v),
									},
								}}
								placeholder='Full Name'
								helperText={errors?.name?.message}
								error={errors?.name ? true : false}
								Icon={<FaUser fontSize='large' />}
							/>
						</Box>

						<Box {...inputBoxStyles}>
							<FormLabel htmlFor='phoneNumber'>Mobile</FormLabel>
							<FormInput
								name='phoneNumber'
								control={control}
								rules={{
									validate: {
										checkRequired: (v: string) => validateRequired(v),
									},
								}}
								placeholder='Mobile number'
								helperText={errors?.phoneNumber?.message}
								error={errors?.phoneNumber ? true : false}
								Icon={<MdOutlineSmartphone fontSize='large' />}
								type='tel'
							/>
						</Box>

						<Box {...inputBoxStyles}>
							<FormLabel htmlFor='email'>Email</FormLabel>
							<FormInput
								name='email'
								control={control}
								rules={{
									validate: {
										checkEmail: (v: string) => validateEmail(v),
									},
								}}
								placeholder='Email'
								helperText={errors?.email?.message}
								error={errors?.email ? true : false}
								Icon={<EmailIcon color='gray.400' fontSize='22px' />}
							/>
						</Box>

						<Box {...inputBoxStyles}>
							<FormLabel htmlFor='company'>Company</FormLabel>
							<FormInput
								name='company'
								control={control}
								rules={{
									validate: {
										checkRequired: (v: string) => validateRequired(v),
									},
								}}
								placeholder='Company Name'
								helperText={errors?.company?.message}
								error={errors?.company ? true : false}
								Icon={<BsBuilding fontSize='large' />}
							/>
						</Box>

						<Box {...inputBoxStyles}>
							<Box>
								<label style={{ display: 'flex', alignItems: 'center' }}>
									<FormLabel htmlFor='password'>Password</FormLabel>
									<Tooltip
										bgColor='#70CBCF'
										label={
											<div>
												<span>Passwords must contain:</span>
												<ul>
													<li>8 characters</li>
													<li>An uppercase letter</li>
													<li>A lowercase letter</li>
													<li>A special character</li>
													<li>A number</li>
												</ul>
											</div>
										}
									>
										<InfoIcon color='#70CBCF' style={{ marginLeft: '4px', marginBottom: '9px' }} />
									</Tooltip>
								</label>
							</Box>
							<PasswordInput
								name='password'
								control={control}
								rules={{
									validate: {
										checkPassword: (v: string) => validatePassword(v),
									},
								}}
								placeholder='Password'
								helperText={errors?.password?.message}
								error={errors?.password ? true : false}
								label=''
							/>
						</Box>

						<Box {...inputBoxStyles}>
							<Box>
								<label style={{ display: 'flex', alignItems: 'center' }}>
									<FormLabel htmlFor='password'>Confirm Password</FormLabel>
									<Tooltip
										bgColor='#70CBCF'
										label={
											<div>
												<span>Passwords must contain:</span>
												<ul>
													<li>8 characters</li>
													<li>An uppercase letter</li>
													<li>A lowercase letter</li>
													<li>A special character</li>
													<li>A number</li>
												</ul>
											</div>
										}
									>
										<InfoIcon color='#70CBCF' style={{ marginLeft: '4px', marginBottom: '9px' }} />
									</Tooltip>
								</label>
							</Box>
							<PasswordInput
								name='confirmPassword'
								control={control}
								rules={{
									validate: {
										checkPassword: (v: string) => checkPasswordMatch(v, watch('password')),
									},
								}}
								placeholder='Password'
								error={errors?.confirmPassword ? true : false}
								helperText={errors?.confirmPassword?.message}
								label=''
							/>
						</Box>

						<Box {...inputBoxStyles}>
							<FormLabel htmlFor='estimatedAnnualRevenue'>Estimated Annual Revenue</FormLabel>
							<Controller
								name='estimatedAnnualRevenue'
								control={control}
								render={({ field }) => (
									<Select
										{...field}
										id='estimatedAnnualRevenue'
										placeholder='Please choose'
										fontSize='14px'
										color='gray'
										onChange={(e) => {
											field.onChange(e)
											handleRevenueChange(e)
										}}
									>
										{yearlyRevenueOptions.map((option) => (
											<option key={option.key} value={option.key}>
												{option.label}
											</option>
										))}
									</Select>
								)}
							/>
						</Box>

						<Box {...inputBoxStyles} mt='30px'>
							<FormLabel htmlFor='referrer'>Referral Code (Optional)</FormLabel>
							<FormInput
								name='referrer'
								control={control}
								placeholder='Referral Code (Optional)'
								helperText={errors?.referrer?.message}
								error={errors?.referrer ? true : false}
								Icon={<LockIcon fontSize='large' />}
							/>
						</Box>

						<Box {...inputBoxStyles} fontSize='xs' color='gray' mt='20px'>
							<Flex align='center' padding={1}>
								<Controller
									name='terms'
									control={control}
									rules={{ required: true }}
									render={({ field }) => (
										<Checkbox isChecked={field.value} onChange={field.onChange} colorScheme='blue' />
									)}
								/>
								<Text pl='5px'>I agree to all </Text>
								<Link
									as={RRLink}
									to='/terms_and_conditions'
									target='_blank'
									rel='noreferrer'
									p='3px'
									color='blue.500'
									_visited={{ color: 'blue.500' }}
								>
									Terms
								</Link>{' '}
								and{' '}
								<Link
									as={RRLink}
									to='/privacy_policy'
									target='_blank'
									rel='noreferrer'
									p='3px'
									color='blue.500'
									_visited={{ color: 'blue.500' }}
								>
									Privacy Policy
								</Link>
							</Flex>
							{errors.terms && (
								<Box color='red.500' fontSize='sm'>
									Required
								</Box>
							)}
						</Box>

						<ReCaptcha ref={recaptchaRef} sitekey={import.meta.env.VITE_CAPTCHA_PUBLIC_SITE_KEY} size='invisible' />

						<Flex align='center' justify='center' w='100%' marginTop='10px'>
							<PrimaryButton
								type='submit'
								isDisabled={loading}
								isLoading={loading}
								colorScheme='blue'
								color='white'
								width={buttonWidth}
							>
								Create Account
							</PrimaryButton>
						</Flex>

						<Box {...inputBoxStyles} mt='30px'>
							<Box fontSize='sm'>
								Already have an account?
								<ChakraLink
									as={ReactRouterLink}
									to='/login'
									color='blue.500'
									_visited={{ color: 'blue.400' }}
									fontSize='12px'
									ml='4px'
								>
									Log in
								</ChakraLink>
							</Box>
						</Box>
					</Box>
				</Flex>

				<Box width='100%' textAlign='center' color='white' fontSize='xs' fontWeight='bold'>
					© 2024, Valence Intelligence
				</Box>
			</VStack>
		</Flex>
	)
}

export default Signup

const inputBoxStyles = {
	w: '100%',
	m: '10px 0px',
}
